<template>
  <div class="figure__wrapper" :class="{ 'figure__wrapper-details': isDetails }">
    <svg
      :width="defaultSvgParams.width * figureSvgScale"
      :height="defaultSvgParams.height * figureSvgScale"
      :viewBox="`0 0 ${defaultSvgParams.width * figureSvgScale}
           ${defaultSvgParams.height * figureSvgScale}`"
      ref="figure"
      :style="{ transform: `matrix(${zoom},0,0,${zoom},0,0)` }"
    >
      <ruler
        :x1="0"
        :y1="changeHeightUp"
        :x2="defaultSvgParams.width"
        :y2="changeHeightUp"
        v-if="!isNav"
      />
      <ruler
        :x1="0"
        :y1="changeHeightDown"
        :x2="defaultSvgParams.width"
        :y2="changeHeightDown"
        v-if="!isNav"
      />
      <ruler
        :x1="changeWidthLeft"
        :y1="0"
        :x2="changeWidthLeft"
        :y2="defaultSvgParams.height"
        v-if="!isNav"
      />
      <ruler
        :x1="changeWidthRight"
        :y1="0"
        :x2="changeWidthRight"
        :y2="defaultSvgParams.height"
        v-if="!isNav"
      />
      <size-arrow :path="arrowHeight" :is-details="isDetails" color="blue" v-if="!isNav" />
      <size-arrow :path="arrowWidth" :is-details="isDetails" color="green" v-if="!isNav" />
      <size
        v-if="!isNav"
        :x="changeWidthRight + 30"
        :y="(defaultSvgParams.height * figureSvgScale) / 2"
        :text="`E = ${this.slopeParams.height}`"
        :fill="isDetails ? colors.grey : colors.blue"
      />
      <size
        v-if="!isNav"
        :x="(defaultSvgParams.width * figureSvgScale) / 2"
        :y="changeHeightDown + 30"
        :text="`A = ${slopeParams.width}`"
        :fill="isDetails ? colors.grey : colors.green"
      />
      <!-- :transform="`rotate(-90 ${changeWidthRight + 20} ${(this.defaultSvgParams.height *
      this.figureSvgScale) / 2})`" -->
      <defs v-if="isPlate">
        <clipPath :id="`figureMask--sector-${slopeFromState.uid}`">
          <path
            :d="squareMask"
            fill="none"
            :stroke="figureStrokeColor"
            :stroke-width="strokeWidth"
            style="stroke-linecap: round"
          ></path>
          // square mask
        </clipPath>
      </defs>
      <g
        v-if="isDetails"
        :clip-path="`url(#figureMask--sector-${slopeFromState.uid})`"
        name="rowsForTooltip"
      >
        <!--:clip-path="`url(#figureMask--sector-${slopeFromState.uid})`"-->

        <g v-for="i in rowsCount" :key="i" class="row__overlay">
          <rect
            :x="changeWidthRight - plateWidth * i"
            :y="i % 2 === 0 ? rowStartY - plateHeight / 2 : rowStartY"
            :width="plateWidth - 1"
            :height="i % 2 === 0 ? rowHeight + plateHeight : rowHeight"
            fill="#F6F6F6"
            stroke="#999999"
            stroke-width="1"
            style="shape-rendering: crispEdges"
            @mouseover="showRowInfo(i)"
            @mouseout="hideRowInfo()"
          ></rect>
          <line
            :x1="changeWidthRight - plateWidth * i"
            :y1="
              i % 2 === 0
                ? rowStartY - plateHeight / 2 + plateHeight * n
                : rowStartY + plateHeight * n
            "
            :x2="changeWidthRight - plateWidth * i + plateWidth - 1"
            :y2="
              i % 2 === 0
                ? rowStartY - plateHeight / 2 + plateHeight * n
                : rowStartY + plateHeight * n
            "
            stroke="#999999"
            stroke-width="1"
            style="shape-rendering: crispEdges"
            v-for="n in i % 2 === 0 ? itemPerRow : itemPerRow - 1"
            :key="n"
          ></line>
          <!-- <plate-dividers
            :index="i"
            :rowStartY="rowStartY"
            :plateWidth="plateWidth"
            :plateHeight="plateHeight"
            :itemPerRow="itemPerRow"
            :changeWidthRight="changeWidthRight - plateWidth * (i - 1)"
          /> -->
          <row-number :opts="getRowNumberOpts(i)" />
        </g>
      </g>
      <size-arrow
        :path="arrowDirection"
        :is-details="isDetails"
        :color="arrowStrokeColor"
        :stroke-width="arrowDirectionStrokeWidth"
        :stroke-dasharray="isDetails ? 20 : 0"
        :opacity="isDetails ? 0.7 : 1"
      />
      <path
        :d="square"
        class="scheme-circuit"
        fill="none"
        :stroke="figureStrokeColor"
        :stroke-width="strokeWidth"
        style="stroke-linecap: round"
      ></path>
    </svg>
  </div>
</template>

<script>
import {
  getRowNumberFontSize,
  getRowNumberX,
  colors,
  drawSizeArrowVertical,
  drawSizeArrowHorizontal,
  drawArrowDirection
} from '@/utils/drawing'
import Size from '@/components/figure_elements/Size'
import RowNumber from '@/components/figure_elements/RowNumber'
import SizeArrow from '@/components/figure_elements/SizeArrow'
import Ruler from '@/components/figure_elements/Ruler'
import { getMin } from '@/utils'

export default {
  props: {
    rtfy: Object,
    isNav: Boolean,
    defaultSvgParams: Object,
    isDetails: Boolean,
    isPlate: Boolean,
    navId: Number,
    zoom: Number,
    rowId: {
      type: Number,
      default: 0
    },
    isForTotalPdf: {
      type: Boolean
    },
    sector: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    Ruler,
    SizeArrow,
    RowNumber,
    Size
  },
  methods: {
    getRowNumberOpts(i) {
      return {
        fontSize: getRowNumberFontSize(this.plateWidth),
        x: getRowNumberX(this.changeWidthRight, this.plateWidth, i),
        y: this.arrowDirectionY,
        text: i // getRandomId() // i
      }
    },
    showRowInfo(i) {
      if (this.rowId !== i) {
        this.$emit('updateRowId', i)
      }
    },
    hideRowInfo() {
      if (this.rowId !== 0) {
        this.$emit('updateRowId', 0)
      }
    }
  },
  computed: {
    colors: () => colors,
    slopeFromState() {
      if (this.isForTotalPdf) {
        return this.sector
      } else {
        return (!this.isNav && !this.isDetails) || (this.isDetails && this.isPlate)
          ? this.$store.getters.getSlope(this.slopeId)
          : this.$store.getters.getSlope(this.navId)
      }
    },
    slopeId() {
      return this.$route.params.id ? this.$route.params.id : 0
    },
    allSlopes() {
      return this.$store.getters.allSlopes
    },
    slopeParams() {
      return this.slopeFromState?.slopeParams[0]
    },
    rowsCount() {
      return Math.ceil(
        (this.slopeFromState.slopeParams[0].width * 1000) / this.slopeFromState.materialSize.width
      )
    },
    itemPerRow() {
      return (
        Math.ceil(
          this.defaultSvgParams?.height /
            ((this.slopeFromState.materialSize.height / 1000) * this.scale)
        ) + 1
      )
    },
    plateHeight() {
      return (this.slopeFromState.materialSize.height / 1000) * this.scale
    },
    plateWidth() {
      return (this.slopeFromState.materialSize.width / 1000) * this.scale
    },
    rowHeight() {
      return this.itemPerRow * this.plateHeight
    },
    rowStartY() {
      return this.changeHeightDown - this.rowHeight
    },
    arrowHeight() {
      const { changeHeightUp, changeHeightDown, changeWidthRight } = this
      const x = changeWidthRight + 30
      return drawSizeArrowVertical(x, changeHeightUp, changeHeightDown)
    },
    arrowWidth() {
      const { changeHeightDown, changeWidthLeft, changeWidthRight } = this
      const y = changeHeightDown + 30
      return drawSizeArrowHorizontal(changeWidthLeft, y, changeWidthRight)
    },
    arrowDirection() {
      const { arrowDirectionStartX, arrowDirectionEndX, arrowDirectionY, arrowDirectionSize } = this
      return drawArrowDirection(
        arrowDirectionStartX,
        arrowDirectionEndX,
        arrowDirectionY,
        arrowDirectionSize
      )
    },
    square() {
      const { changeWidthLeft, changeHeightUp, changeWidthRight, changeHeightDown } = this
      return `M${changeWidthLeft},${changeHeightUp}
      L${changeWidthLeft},${changeHeightDown}
      M${changeWidthLeft},${changeHeightDown}
      L${changeWidthRight},${changeHeightDown}
      M${changeWidthRight},${changeHeightDown}
      L${changeWidthRight},${changeHeightUp}
      M${changeWidthRight},${changeHeightUp}
      L${changeWidthLeft},${changeHeightUp}`
    },
    squareMask() {
      const { changeWidthLeft, changeHeightUp, changeWidthRight, changeHeightDown } = this
      return `M${changeWidthLeft},${changeHeightUp}
      L${changeWidthLeft},${changeHeightDown}
      L${changeWidthRight},${changeHeightDown}
      L${changeWidthRight},${changeHeightUp}
      L${changeWidthLeft},${changeHeightUp} Z`
    },
    arrowStrokeColor() {
      return this.isNav ? 'nav' : 'grey'
    },
    figureStrokeColor() {
      return this.isNav || this.isDetails ? 'var(--grey-stroke)' : 'var(--black)'
    },
    strokeWidth() {
      return this.isNav || this.isDetails ? 2 : 3
    },
    arrowDirectionStrokeWidth() {
      return this.isNav || this.isDetails ? 2 : 4
    },

    figureSvgScale() {
      return this.isNav ? this.defaultSvgParams.navScale : 1
    },
    arrowDirectionSize() {
      return this.isNav ? 5 : 10
    },
    scaleH() {
      // коэффициент высоты
      return (
        (this.defaultSvgParams.baseHeight * this.figureSvgScale * 100) / this.slopeParams.height
      )
    },
    scaleW() {
      // коэффициент ширины
      return (this.defaultSvgParams.baseWidth * this.figureSvgScale * 100) / this.slopeParams.width
    },
    scale() {
      // применяемый коэффициент в зависимости от того какой из коэффициентов больше
      return getMin(this.scaleW, this.scaleH)
    },
    changeWidthLeft() {
      // изменяем ширину влево
      return (
        (this.defaultSvgParams.width * this.figureSvgScale) / 2 -
        (this.slopeParams.width * this.scale) / 2
      )
    },
    changeWidthRight() {
      // изменяем ширину вправо
      return (
        (this.defaultSvgParams.width * this.figureSvgScale) / 2 +
        (this.slopeParams.width * this.scale) / 2
      )
    },
    changeHeightUp() {
      // изменяем высоту вверх
      return (
        (this.defaultSvgParams.height * this.figureSvgScale) / 2 -
        (this.slopeParams.height * this.scale) / 2
      )
    },
    changeHeightDown() {
      // изменяем высоту вниз
      return (
        (this.defaultSvgParams.height * this.figureSvgScale) / 2 +
        (this.slopeParams.height * this.scale) / 2
      )
    },
    arrowDirectionStartX() {
      // изменяем ширину направляющей стрелки влево
      return (
        (this.defaultSvgParams.width * this.figureSvgScale) / 2 -
        ((this.slopeParams.width / 2) * this.scale) / 2
      )
    },
    arrowDirectionEndX() {
      // изменяем ширину напрвляющей стрелки вправо
      return (
        (this.defaultSvgParams.width * this.figureSvgScale) / 2 +
        ((this.slopeParams.width / 2) * this.scale) / 2
      )
    },
    arrowDirectionY() {
      // изменяем положение напрвляющей стрелки по высоте
      return (this.changeHeightDown + this.changeHeightUp) / 2
    }
    // matrixZoom() {
    //   if (this.isDetails && this.zoom > 1) {
    //     return matrixZoom(this.zoom, this.defaultSvgParams, this.$refs.figureWrapper)
    //   } else {
    //     return 'matrix(1,0,0,1,0,0)'
    //   }
    // }
  },
  mounted() {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass"></style>
